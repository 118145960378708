import React, { Component } from 'react';
import { Index } from 'elasticlunr';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Components
import SearchIcon from '../icons/search';

const SearchContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;

  display: ${props => (props.isSearchOpen ? 'block' : 'none')};
  z-index: 1;
`;

const SearchButton = styled.button`
  position: absolute;
  right: 20px;

  z-index: 10;

  margin: 0 !important;
  line-height: 1;
  pointer-events: all;

  padding: 10px;

  background-color: transparent;

  @media (max-width: 768px) {
    right: 10px;
  }

  @media (max-width: 500px) {
    padding: 10px 5px;

    right: 15px;
    top: 10px;
  }

  @media (max-width: 374px) {
    right: 5px;
  }
`;

const GoButton = styled.button`
  padding: 2px 5px;
  line-height: 20px;

  background-color: #323232;

  transition: 150ms all ease;
  will-change: all;

  & > a {
    margin: 0 !important;

    font-size: 10px !important;
    text-transform: uppercase !important;
    color: #fff !important;
  }

  & .disabled-link {
    pointer-events: none;
  }
`;

const SearchInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: calc(100% - 30px);
  }

  & input {
    height: 25px;
    width: calc(100% - 55px - 20px);

    margin: 0 0 0 20px;

    border: 1px solid #e8e8e8;
    border-right: 1px solid #323232;
    border-radius: 0;

    transition: 250ms border-color ease;

    padding: 2px 5px;

    font-size: 12px;
    color: #323232;

    -webkit-appearance: none;

    &:focus {
      border: 1px solid #323232;
      outline: none;
    }
  }
`;

const CloseIcon = styled.span`
  width: 23px;
  font-size: 20px;

  margin-top: 6px;
`;

// Search component
export default class MobileSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ``,
      results: [],
    };
  }

  render() {
    const searchResults = this.state.results;
    const searchQuery = this.state.query;

    // https://dev.to/steelvoltage/tip-disabling-buttons-as-links-in-gatsby-3o5n
    const disableLink = searchQuery ? '' : 'disabled-link';

    return (
      <>
        <SearchButton onClick={() => this.props.onClick()}>
          {!this.props.isSearchOpen && <SearchIcon />}
          {this.props.isSearchOpen && <CloseIcon>✕</CloseIcon>}
        </SearchButton>

        <SearchContainer isSearchOpen={this.props.isSearchOpen}>
          <SearchInner>
            {this.props.isSearchOpen && (
              <div>
                <input
                  type="text"
                  value={this.state.query}
                  onChange={this.search}
                  id="search-input"
                />

                <GoButton>
                  <Link
                    disabled={this.state.query === ''}
                    className={`button ${disableLink}`}
                    to={`/search`}
                    state={{
                      searchResults,
                      searchQuery,
                    }}
                    onClick={() => this.props.onClick()}
                  >
                    Search
                  </Link>
                </GoButton>
              </div>
            )}
          </SearchInner>
        </SearchContainer>
      </>
    );
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex);

  search = evt => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    });
  };
}
