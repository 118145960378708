import React, { useState, useEffect } from 'react';
import Media from 'react-media';
import { useStaticQuery, graphql } from 'gatsby';

// Components
import MobileMenuComponent from './mobile-menu-component';
import DesktopMenuComponent from './desktop-menu-component';

import { useMediaQuery } from '../hooks/useMediaQuery';

const Navigation = () => {
  // const [windowWidth, setWindowWidth] = useState(1200);

  // Media Query
  let isDesktop = useMediaQuery('(min-width: 1450px)');

  // useEffect(() => {
  //   // Get window dimensions on load
  //   if (typeof window !== `undefined`) {
  //     let windowWidthOnLoad = window.innerWidth;
  //     setWindowWidth(windowWidthOnLoad);
  //   }
  // }, []);

  const data = useStaticQuery(graphql`
    query {
      siteSearchIndex {
        index
      }
      prismicCollectionsMenu {
        data {
          menu {
            name
            link
          }
        }
      }
      prismicMenus {
        data {
          header_left_side {
            link_title
            header_left_link {
              url
              link_type
              document {
                ... on PrismicContactUs {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicStockists {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicGallery {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicJournal {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicContract {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          header_right_side {
            link_title
            header_right_link {
              url
              link_type
              document {
                ... on PrismicContactUs {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicStockists {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicGallery {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicJournal {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicContract {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {isDesktop ? (
        <DesktopMenuComponent data={data} />
      ) : (
        <MobileMenuComponent data={data} />
      )}
    </>
  );
};

export default Navigation;
