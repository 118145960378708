import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

// Components
import { FooterMailchimpSignUpForm } from '../mailchimp/footer-mailchimp';
import Twitter from '../icons/twitter';
import Facebook from '../icons/facebook';
import Pinterest from '../icons/pinterest';
import Instagram from '../icons/instagram';

const FooterContainer = styled.footer`
  width: 100%;
  margin: 30px 0 0 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  box-shadow: 0px -1px 1px rgba(50, 50, 50, 0.1);
  background-color: rgb(246, 246, 246);
  line-height: 1;

  font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif;
  color: #323232;

  padding: 0 60px;
  @media (max-width: 1200px) {
    padding: 0 30px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    margin-top: 30px;
  }
`;

const FooterTier = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;

  padding: 30px 0;
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & a {
    line-height: 1;
  }

  & svg {
    width: 20px;
    height: 20px;

    margin-right: 30px;
    @media (max-width: 1200px) {
      margin-right: 20px;
    }
  }

  & a:last-of-type {
    & svg {
      margin: 0;
    }
  }

  @media (max-width: 500px) {
    padding: 10px 0;
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  & li {
    list-style: none;

    margin-right: 30px;
    @media (max-width: 1200px) {
      margin-right: 20px;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    padding: 20px 0;

    & li {
      padding: 10px 0;
      margin: 0;
      width: 100%;
    }
  }
`;

const Copyright = styled.div`
  line-height: 1;
  padding: 0 0 30px 0;

  font-size: 10px;

  & a {
    margin: 0 0 0 30px;
  }

  @media (max-width: 500px) {
    & span {
      display: block;
      width: 100%;
    }

    & a {
      margin: 10px 0 0 0;
      display: block;
    }

    padding: 0 0 30px 0;
  }
`;

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  const data = useStaticQuery(graphql`
    {
      prismicMenus {
        data {
          footer {
            footer_links {
              document {
                ... on PrismicContactUs {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicStockists {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicGallery {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicJournal {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const footerLinks = data.prismicMenus.data.footer.map((link, index) => (
    <li key={`single_footer_link_${index}_${link.footer_links.document.id}`}>
      <Link
        to={link.footer_links.document.url}
        activeStyle={{ color: '#934E2A' }}
      >
        {link.footer_links.document.data.title.text}{' '}
      </Link>
    </li>
  ));

  return (
    <FooterContainer>
      <FooterTier>
        <FooterMailchimpSignUpForm />

        <Menu>{footerLinks}</Menu>

        <SocialContainer>
          <a
            href="https://twitter.com/mournetextiles"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter />
          </a>
          <a
            href="https://www.facebook.com/mournetextiles"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook />
          </a>
          <a
            href="https://www.pinterest.com/mournetextiles/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Pinterest />
          </a>
          <a
            href="https://www.instagram.com/mournetextiles/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram />
          </a>
        </SocialContainer>
      </FooterTier>

      <Copyright>
        <span>© {year} Mourne Textiles</span>
        <span>
          <a
            href="https://www.kieranstartup.co.uk"
            target="_blank"
            rel="noreferrer noopener"
          >
            Site Credits
          </a>
        </span>
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
