import React, { useState, useEffect } from 'react';
import { Normalize } from 'styled-normalize';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

// Cookie stuff
import { useLocation } from '@reach/router'; // this helps tracking the location

// Components
import GlobalStyle from '../styles/globalStyles';
import Navigation from '../navigation/navigation';
import Footer from './footer';
import { NewsBar } from './news-bar';
import { CookieConsentBar } from './cookie-consent';
import { MailchimpSignUpForm } from '../mailchimp/mailchimp';

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 15,
  },
  container: {
    padding: 60,
    maxWidth: {
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const SiteWrapper = styled.div`
  margin-top: 80px;
  background: #fff;

  display: flex;
  min-height: calc(100vh - 80px);
  flex-direction: column;

  @media (max-width: 1201px) {
    margin-top: 60px;
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      prismicMailchimp {
        data {
          display_on_website
        }
      }
      prismicNewsBar {
        data {
          display_news_bar
        }
      }
      prismicSeo {
        data {
          seo_description {
            text
          }
          seo_image {
            url(imgixParams: { width: 1200, height: 630 })
          }
          seo_keywords {
            text
          }
        }
      }
    }
  `);

  const [isMailchimpVisible, setMailchimpVisibility] = useState(false);
  const [hideMailchimp, setHideMailchimp] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let isMTMailchimpClosed = localStorage.getItem('isMTMailchimpClosed');

      if (isMTMailchimpClosed === true || isMTMailchimpClosed === null) {
        setTimeout(() => {
          setMailchimpVisibility(true);
        }, 3000);
      } else {
        setMailchimpVisibility(false);
      }
    }
  }, []);

  const closeMailchimp = () => {
    setMailchimpVisibility(false);
    localStorage.setItem('isMTMailchimpClosed', true);

    setTimeout(() => {
      setHideMailchimp(true);
    }, 550);
  };

  const location = useLocation();
  const [hasAnalyticsInitialized, setHasAnalyticsInitialized] = useState(false);
  const [isAnalyticsSet, setIsAnalyticsSet] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const isGAInitialized = setTimeout(() => {
        if (window.gatsbyPluginGDPRCookiesGoogleAnalyticsInitialized) {
          setHasAnalyticsInitialized(true);
        }
      }, 2000);
      return () => clearTimeout(isGAInitialized);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.gatsbyPluginGDPRCookiesGoogleAnalyticsInitialized) {
        setHasAnalyticsInitialized(true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (
      typeof window !== `undefined` &&
      hasAnalyticsInitialized === true &&
      isAnalyticsSet === false
    ) {
      window.gtag('config', 'UA-44198806-1', {
        linker: {
          domains: ['mournetextiles.com', 'mourne-textiles.myshopify.com'],
        },
      });

      setIsAnalyticsSet(true);
    }
  }, [hasAnalyticsInitialized]);

  return (
    <GridThemeProvider gridTheme={gridTheme}>
      <Helmet
        title={`Mourne Textiles`}
        meta={[
          {
            name: 'description',
            content: `${data.prismicSeo.data.seo_description.text}`,
          },
          {
            name: 'keywords',
            content: `${data.prismicSeo.data.seo_keywords.text}`,
          },
          {
            name: 'og:image',
            content: `${data.prismicSeo.data.seo_image.url}`,
          },
          {
            name: 'og:image:secure_url',
            content: `${data.prismicSeo.data.seo_image.url}`,
          },
          {
            name: 'og:description',
            content: `${data.prismicSeo.data.seo_description.text}`,
          },
          {
            name: 'og:image:width',
            content: `1200`,
          },
          {
            name: 'og:image:height',
            content: `630`,
          },
          {
            name: 'og:locale',
            content: `en`,
          },
          {
            name: 'twitter:title',
            content: `Mourne Textiles`,
          },
          {
            name: 'twitter:description',
            content: `${data.prismicSeo.data.seo_description.text}`,
          },
          {
            name: 'twitter:card',
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${data.prismicSeo.data.seo_image.url}`,
          },
          {
            name: 'facebook-domain-verification',
            content: `zhhphs3hld1xqx0khxtqed4cw2k6i6`,
          },
        ]}
        link={[
          {
            rel: 'dns-prefetch',
            href: 'https://images.prismic.io/mourne-textiles',
          },
          {
            rel: 'preconnect',
            href: 'https://images.prismic.io/mourne-textiles',
          },
          {
            rel: 'dns-prefetch',
            href: 'https://cdn.shopify.com',
          },
          {
            rel: 'preconnect',
            href: 'https://cdn.shopify.com',
          },
          {
            rel: 'preload',
            href: '/fonts/fonts.css',
            as: 'style',
          },
          {
            rel: 'preload',
            href: '/fonts/Garnett-Regular.eot',
            as: 'font',
            crossOrigin: 'anonymous',
          },
          {
            rel: 'preload',
            href: '/fonts/Garnett-Regular.woff2',
            as: 'font',
            crossOrigin: 'anonymous',
          },
          {
            rel: 'preload',
            href: '/fonts/Garnett-Regular.woff',
            as: 'font',
            crossOrigin: 'anonymous',
          },
          {
            rel: 'preload',
            href: '/fonts/Garnett-Light.eot',
            as: 'font',
            crossOrigin: 'anonymous',
          },
          {
            rel: 'preload',
            href: '/fonts/Garnett-Light.woff2',
            as: 'font',
            crossOrigin: 'anonymous',
          },
          {
            rel: 'preload',
            href: '/fonts/Garnett-Light.woff',
            as: 'font',
            crossOrigin: 'anonymous',
          },
          {
            rel: 'preload',
            href: '/fonts/Garnett-LightItalic.eot',
            as: 'font',
            crossOrigin: 'anonymous',
          },
          {
            rel: 'preload',
            href: '/fonts/Garnett-LightItalic.woff2',
            as: 'font',
            crossOrigin: 'anonymous',
          },
          {
            rel: 'preload',
            href: '/fonts/Garnett-LightItalic.woff',
            as: 'font',
            crossOrigin: 'anonymous',
          },
        ]}
      />
      <Normalize />
      <BaseCSS />
      <GlobalStyle />
      <Navigation />
      <SiteWrapper id="page-wrap">
        <>
          {data.prismicMailchimp.data.display_on_website === true &&
            hideMailchimp === false && (
              <MailchimpSignUpForm
                onClick={() => closeMailchimp()}
                opacity={isMailchimpVisible === true ? 1 : 0}
                display={isMailchimpVisible === true ? `block` : `none`}
              />
            )}
          {data.prismicNewsBar.data.display_news_bar === true && <NewsBar />}
          {children}
          <Footer />
          <CookieConsentBar
            hasAnalyticsInitialized={hasAnalyticsInitialized}
            setHasAnalyticsInitialized={setHasAnalyticsInitialized}
            isAnalyticsSet={isAnalyticsSet}
            setIsAnalyticsSet={setIsAnalyticsSet}
          />
        </>
      </SiteWrapper>
    </GridThemeProvider>
  );
};

export default Layout;
