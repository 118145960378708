import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';

export const CookieConsentBar = ({
  hasAnalyticsInitialized,
  setHasAnalyticsInitialized,
  isAnalyticsSet,
  setIsAnalyticsSet,
}) => {
  const location = useLocation();

  const checkIfInitialized = () => {
    if (typeof window !== `undefined`) {
      const isGAInitialized = setTimeout(() => {
        if (window.gatsbyPluginGDPRCookiesGoogleAnalyticsInitialized) {
          setHasAnalyticsInitialized(true);
        }
      }, 2000);
      return () => clearTimeout(isGAInitialized);
    }
  };

  useEffect(() => {
    if (
      typeof window !== `undefined` &&
      hasAnalyticsInitialized === true &&
      isAnalyticsSet === false
    ) {
      window.gtag('config', 'UA-44198806-1', {
        linker: {
          domains: ['mournetextiles.com', 'mourne-textiles.myshopify.com'],
        },
      });

      setIsAnalyticsSet(true);
    }
  }, [hasAnalyticsInitialized]);

  return (
    <CookieConsent
      buttonText="Accept"
      cookieName="mt-google-analytics"
      containerClasses="cookie-consent-container"
      disableStyles={true}
      onAccept={() => {
        initializeAndTrack(location);
        checkIfInitialized();
      }}
    >
      This website uses cookies to enhance the user experience. Close this
      dialog to confirm you are happy with that, or find out more in our{' '}
      <Link to={`/privacy-policy`}>privacy policy</Link>.
    </CookieConsent>
  );
};
