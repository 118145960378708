import React, { useContext, useState, useEffect } from 'react';

// Components
import { StoreContext } from '../context/store-context';
import Cart from '../icons/cart';

const countQuantity = lineItems => {
  let quantity = 0;

  lineItems.forEach(item => {
    quantity = quantity + item.quantity;
  });

  return quantity;
};

const CartNavigationWrapper = () => {
  const { checkout, client } = useContext(StoreContext);
  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  );

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []));
  }, [checkout]);

  return <Cart quantity={quantity} />;
};

export default CartNavigationWrapper;
