import React, { useContext } from 'react';
import styled from 'styled-components';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import { StoreContext } from '../context/store-context';
import FormatProductTitle from '../utils/format-product-title';
import FormatPrice from '../utils/format-price';

const ProductCard = styled.div`
  margin-bottom: 40px;

  font-feature-settings: 'tnum';

  @media (max - width: 767px) {
    margin-bottom: 20px;
  }

  & h3 {
    margin: 15px 0 0 0;
  }

  & h3 {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  & h4,
  & p {
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }

  h3,
  h4 {
    font-size: 14px;
  }

  & h4 {
    color: rgba(50, 50, 50, 0.5);
    margin: 0 0 15px 0;
  }

  & p {
    font-size: 13px;

    margin: 0;
    color: #323232;
  }
`;

const FlexPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
`;

const Remove = styled.button`
  font-size: 25px;
  font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif !important;
  line-height: 1;

  &:hover {
    color: #934e2a;
  }
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const QuantityToggle = styled.button`
  font-size: 18px;
  font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif !important;

  width: 30px;

  &:hover {
    color: #934e2a;
  }
`;

const LineItem = props => {
  const { line_item } = props;
  const { removeLineItem, updateLineItem, client, checkout } = useContext(
    StoreContext
  );

  const variantImage = line_item.variant.image ? (
    <img
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
    />
  ) : null;

  const handleRemove = () => {
    removeLineItem(checkout.id, line_item.id);
  };

  const increaseQuantity = () => {
    updateLineItem(checkout.id, line_item.id, line_item.quantity + 1);
  };

  const decreaseQuantity = () => {
    updateLineItem(checkout.id, line_item.id, line_item.quantity - 1);
  };

  return (
    <ProductCard>
      <Col col={12}>
        <Row>
          <Col col={12}>{variantImage}</Col>
          <Col col={12}>
            <h3>{FormatProductTitle(line_item.title)[0]}</h3>
            <h4>{FormatProductTitle(line_item.title)[1]}</h4>
          </Col>

          <Col col={12}>
            <FlexPriceContainer>
              <QuantityContainer>
                QTY
                <QuantityToggle onClick={decreaseQuantity}>-</QuantityToggle>
                {line_item.quantity}
                <QuantityToggle onClick={increaseQuantity}>+</QuantityToggle>
              </QuantityContainer>
              {'× '}
              {FormatPrice(line_item.variant.price.amount, 'gbp')}
              <Remove onClick={handleRemove}>×</Remove>
            </FlexPriceContainer>
          </Col>
        </Row>
      </Col>
    </ProductCard>
  );
};

export default LineItem;
