import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  width: 30px;
  height: auto;

  @media (max-width: 500px) {
    width: 25px;
  }
`;

const Path = styled.path`
  fill: ${props => (props.quantity >= 1 ? '#934e2a' : '#000000')};
`;

const QuantityContainer = styled.div`
  position: relative;

  width: 30px;
  height: 25px;

  @media (max-width: 500px) {
    width: 25px;
    height: 20px;
  }
`;

const Quantity = styled.span`
  transition: 500ms opacity ease;
  opacity: ${props => (props.quantity >= 1 ? '1' : '0')};
  color: #934e2a;

  position: absolute;
  top: 12px;

  left: 50%;
  transform: translateX(-50%);
  font-size: 7px;

  @media (max-width: 500px) {
    top: 9px;
  }
`;

const Cart = ({ quantity }) => (
  <QuantityContainer>
    <Icon
      quantity={quantity}
      width="33"
      height="25"
      viewBox="0 0 33 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        quantity={quantity}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7977 0H15.7977V8H16.7977V0ZM1.59551 10.9343H16.2978H31L24.7031 23.9343H16.2978H7.89238L1.59551 10.9343ZM31.7978 9.93433H16.2978H0.797754H0L0.347764 10.6523L7.12901 24.6523L7.26562 24.9343H7.579H16.2978H25.0165H25.3299L25.4665 24.6523L32.2477 10.6523L32.5955 9.93433H31.7978Z"
      />
    </Icon>
    <Quantity quantity={quantity}>{quantity}</Quantity>
  </QuantityContainer>
);

export default Cart;
