import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg``;

const Facebook = () => (
  <Icon
    width="13"
    height="26"
    viewBox="0 0 13 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.45312 25.5625V14.1367H12.2617L12.8477 9.69336H8.45312V6.86133C8.45312 6.22656 8.58333 5.71387 8.84375 5.32324C9.12044 4.91634 9.72266 4.71289 10.6504 4.71289H12.9941V0.757812C12.7988 0.708984 12.3838 0.668294 11.749 0.635742C11.1143 0.586914 10.39 0.5625 9.57617 0.5625C8.72982 0.5625 7.94857 0.692708 7.23242 0.953125C6.53255 1.19727 5.93034 1.57161 5.42578 2.07617C4.9375 2.56445 4.55501 3.1748 4.27832 3.90723C4.00163 4.62337 3.86328 5.46159 3.86328 6.42188V9.69336H0.00585938V14.1367H3.86328V25.5625H8.45312Z"
      fill="#323232"
    />
  </Icon>
);

export default Facebook;
