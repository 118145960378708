import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';

// Components
import { StoreContext } from '../context/store-context';
import '../product/select.css';

const GiftWrapping = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;

  border-top: 1px solid #934e2a;
  padding: 20px 0;

  & button {
    margin: 0;
  }

  & p {
    margin: 0;

    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.04em;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;
  }
`;

const OptionsContainer = styled.div`
  width: 100%;
  margin: 1em 0;

  & h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    line-height: 1;

    margin: 0 0 15px 0;
  }

  & .select-css {
    max-width: 100%;
    // border: 1px solid #323232;
    // background-image: url('/icons/down-arrow-active.svg');
  }
`;

const AddToCart = styled.button`
  width: 100%;
  height: 35px;

  text-transform: uppercase;

  border: 1px solid #934e2a;
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #934e2a;

  transition: 150ms all ease;
  will-change: all;

  &:hover {
    background-color: #934e2a;
    color: #fff;
  }
`;

const Grid = styled.div`
  width: 100%;
  // display: grid;
  // grid-template-columns: 1fr 3fr;
  // grid-column-gap: 20px;

  margin: 1em 0;

  & .three-two {
    position: relative;
    overflow: hidden;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 66.667%;
    }
    & > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const GiftWrap = ({ product, data }) => {
  const [isGiftWrapChecked, setIsGiftWrapChecked] = useState(false);
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2: { minVariantPrice },
  } = product;

  const [variant, setVariant] = useState({
    ...initialVariant,
  });

  const {
    addVariantToCart,
    removeLineItem,
    client,
    checkout,
    adding,
  } = useContext(StoreContext);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;
  const [available, setAvailable] = useState(productVariant.availableForSale);

  useEffect(() => {
    const doesGiftWrapExist = checkout.lineItems.filter(
      line_item => line_item.title === 'Gift Wrap'
    );

    if (doesGiftWrapExist.length >= 1) {
      setIsGiftWrapChecked(true);
    }
  }, [checkout, setIsGiftWrapChecked]);

  const handleOptionChange = (optionIndex, { target }) => {
    const { value } = target;
    const currentOptions = [...variant.selectedOptions];

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    };

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    );

    setVariant({
      ...selectedVariant,
    });
  };

  const handleAddToCart = () => {
    addVariantToCart(productVariant.storefrontId, 1);
  };

  const removeGiftWrapping = () => {
    const giftWrapItems = checkout.lineItems.filter(
      line_item => line_item.title === 'Gift Wrap'
    );

    if (giftWrapItems.length >= 1) {
      giftWrapItems.forEach(item => {
        removeLineItem(checkout.id, item.id);
      });
    }
  };

  const toggleGiftWrap = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setIsGiftWrapChecked(value);

    if (value === false) {
      removeGiftWrapping();
    }
  };

  const selectedGiftWrap = checkout.lineItems.filter(
    item => item.title === 'Gift Wrap'
  );

  const variantOptions = options.map(({ id, name, values }, index) => (
    <div key={`single_gift_variant_option_${id}_${index}`}>
      <select
        name={name}
        key={id}
        onChange={event => handleOptionChange(index, event)}
        className="select-css"
        defaultValue={
          selectedGiftWrap.length >= 1
            ? selectedGiftWrap[0].variant.title
            : null
        }
      >
        {values.map(value => (
          <option value={value} key={`${name}-${value}`} disabled={!available}>
            {value}
          </option>
        ))}
      </select>
    </div>
  ));

  return (
    <GiftWrapping>
      <input
        name="add_gift_wrapping"
        type="checkbox"
        checked={isGiftWrapChecked}
        onChange={event => toggleGiftWrap(event)}
      />

      <p>
        {data.prismicGiftWrapping.data.text} £{productVariant.price}
      </p>

      {isGiftWrapChecked && (
        <>
          <Grid>
            <div className="three-two">
              <div className="content">
                <img
                  src={productVariant.image.originalSrc}
                  alt="gift wrap"
                  loading="lazy"
                />
              </div>
            </div>

            {options[0].values.length >= 1 && (
              <>
                <OptionsContainer>{variantOptions}</OptionsContainer>
              </>
            )}
          </Grid>

          <AddToCart
            type="submit"
            disabled={!available || adding}
            onClick={handleAddToCart}
          >
            {adding === true ? 'Added to Basket' : 'Add to Basket'}
          </AddToCart>
        </>
      )}
    </GiftWrapping>
  );
};
