exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contract-js": () => import("./../../../src/pages/contract.js" /* webpackChunkName: "component---src-pages-contract-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-order-confirmation-js": () => import("./../../../src/pages/order-confirmation.js" /* webpackChunkName: "component---src-pages-order-confirmation-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-product-preview-index-js": () => import("./../../../src/pages/product-preview/index.js" /* webpackChunkName: "component---src-pages-product-preview-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-contract-project-js": () => import("./../../../src/templates/contract-project.js" /* webpackChunkName: "component---src-templates-contract-project-js" */),
  "component---src-templates-fabric-collection-js": () => import("./../../../src/templates/fabric-collection.js" /* webpackChunkName: "component---src-templates-fabric-collection-js" */),
  "component---src-templates-fabric-product-js": () => import("./../../../src/templates/fabric-product.js" /* webpackChunkName: "component---src-templates-fabric-product-js" */),
  "component---src-templates-journal-js": () => import("./../../../src/templates/journal.js" /* webpackChunkName: "component---src-templates-journal-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

