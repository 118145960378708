import styled from 'styled-components';
import { media, Row as sbgRow } from 'styled-bootstrap-grid';

export const Row = styled(sbgRow)`
    margin-right: -10px;
    margin-left: -10px;

  ${media.sm`
    margin-right: -10px;
    margin-left: -10px;
  `}
  ${media.md`
    margin-right: -15px;
    margin-left: -15px;
  `}
  ${media.lg`
    margin-right: -15px;
    margin-left: -15px;
  `}
  ${media.xl`
    margin-right: -15px;
    margin-left: -15px;
  `}
`;
