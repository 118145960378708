import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const MailchimpFormContainer = styled.div`
  position: fixed;
  width: 470px;

  left: 30px;
  bottom: 30px;

  background-color: #fff;

  border: 1px solid rgb(50 50 50 / 15%);

  padding: 20px;

  z-index: 100;

  transition: 500ms opacity ease;
  opacity: ${props => props.opacity};
  display: ${props => props.display};

  @media (max-width: 600px) {
    max-width: 460px;
    width: auto;

    left: 20px;
    right: 20px;
    bottom: 20px;
  }

  @media (max-height: 600px) {
    max-height: 400px;
    overflow: scroll;
  }

  @media (max-height: 500px) {
    max-height: 300px;
    overflow: scroll;
  }

  & input {
    ::-webkit-input-placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
    ::-moz-placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
    :-ms-input-placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
    :-moz-placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
    ::placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
  }

  & .gdpr-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    &.display-on-mobile {
      display: none;
    }

    margin: 0 0 20px 0;

    & p {
      margin: 0;
    }

    & .gdpr-checkbox {
      width: 10px;
      height: 10px;

      position: relative;

      margin: 0 10px 0 0;
    }
    & .gdpr-checkbox label {
      width: 10px;
      height: 10px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #cfcfcf;
    }
    & .gdpr-checkbox label:after {
      content: '';
      width: 6px;
      height: 3px;
      position: absolute;
      top: 2px;
      left: 2px;
      border: 1px solid #323232;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    & .gdpr-checkbox label:hover::after {
      opacity: 1;
    }
    & .gdpr-checkbox input[type='checkbox'] {
      width: 10px;
      height: 10px;

      visibility: hidden;
    }
    & .gdpr-checkbox input[type='checkbox']:checked + label:after {
      opacity: 1;
    }
  }
`;

const Text = styled.div`
  margin: 0 0 20px 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  box-sizing: content-box;

  & .field {
    border-bottom: 1px solid #cfcfcf;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    width: 100%;
    margin: 0 0 20px 0;
  }

  & input {
    width: 100%;

    font-size: 14px;
    line-height: 125%;

    padding: 0 0 4px 0;
    margin: 0;
    background-color: transparent;
    -webkit-appearance: none;

    border: 0;

    color: #323232;
    position: relative;

    &:focus {
      outline: none;
    }
  }
`;

const ThankYouMessage = styled.div`
  margin: 20px 0 0 0;
  & p {
    margin: 0;
  }
`;

const SignUpButton = styled.button`
  width: 160px;
  height: 35px;

  margin: 0;
  background-color: #934e2a;

  font-size: 13px;
  letter-spacing: 0.9px;
  text-transform: uppercase;

  color: #fff;

  transition: 150ms all ease;
  will-change: all;

  &:hover {
    background-color: rgba(147, 78, 42, 0.8);
  }
`;

const PrivacyMessage = styled.div`
  width: 100%;

  & p {
    margin: 10px 0 0 0;
    font-size: 10px;
    line-height: 14px;
  }

  & a {
    border-bottom: 1px solid #cfcfcf;

    &:hover {
      color: #323232;
      border-bottom: 1px solid #cfcfcf;
    }
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0 0 15px 0;

  & .title {
    max-width: calc(100% - 50px);

    & h2 {
      font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif !important;
    }
  }

  & button {
    line-height: 1;
    font-size: 16px;
    height: 28px;
  }
`;

export const MailchimpSignUpForm = ({ onClick, opacity, display }) => {
  const [formMessage, setFormMessage] = useState('');

  const [email, setEmail] = useState('');
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');

  const data = useStaticQuery(graphql`
    {
      prismicMailchimp {
        data {
          title {
            html
          }
          text {
            html
          }
          call_to_action_text
          privacy_policy {
            html
          }
          thank_you_message {
            html
          }
        }
      }
    }
  `);

  // 1. via `.then`
  const handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(email, { FNAME: fname, LNAME: lname }) // listFields are optional if you are only capturing the email address.
      .then(mailchimpData => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (mailchimpData.result === `error`) {
          if (mailchimpData.msg === 'The email you entered is not valid.') {
            setFormMessage(`Invalid Email`);
          }

          if (mailchimpData.msg.includes('already subscribed')) {
            setFormMessage(`Email is already subscribed`);
          }
        }

        if (mailchimpData.result === `success`) {
          setFormMessage(data.prismicMailchimp.data.thank_you_message.html);
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  return (
    <MailchimpFormContainer opacity={opacity} display={display}>
      <Heading>
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: data.prismicMailchimp.data.title.html,
          }}
        />
        <button onClick={() => onClick()}>✕</button>
      </Heading>

      <>
        <Text
          dangerouslySetInnerHTML={{
            __html: data.prismicMailchimp.data.text.html,
          }}
        />
        <form onSubmit={e => handleSubmit(e)} className="mailchimp-signup-form">
          <InputContainer>
            <div className="field">
              <input
                type="name"
                onChange={e => setFName(e.target.value)}
                value={fname}
                placeholder="First Name"
                name="FNAME"
                required
                autoComplete="name"
              />
            </div>
            <div className="field">
              <input
                type="name"
                onChange={e => setLName(e.target.value)}
                value={lname}
                placeholder="Last Name"
                name="LNAME"
                required
                autoComplete="name"
              />
            </div>
            <div className="field">
              <input
                type="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                placeholder={`Email`}
                name="email"
                required
                autoComplete="email"
              />
            </div>

            <div className="gdpr-container">
              <div className="gdpr-checkbox">
                <input
                  type="checkbox"
                  value="None"
                  id="gdpr-checkbox"
                  name="gdprcheck"
                  required
                />
                <label htmlFor="gdpr-checkbox"></label>
              </div>

              <PrivacyMessage
                className="small-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicMailchimp.data.privacy_policy.html,
                }}
              />
            </div>

            <SignUpButton type="submit">
              {data.prismicMailchimp.data.call_to_action_text}
            </SignUpButton>
          </InputContainer>
        </form>

        <ThankYouMessage
          dangerouslySetInnerHTML={{
            __html: formMessage,
          }}
        />
      </>
    </MailchimpFormContainer>
  );
};
