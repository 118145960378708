import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  height: auto;

  @media (max-width: 500px) {
    width: 19px;
  }
`;

const SearchIcon = () => (
  <Icon
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5265 15.8438C18.7262 14.2874 19.4398 12.337 19.4398 10.2199C19.4398 5.12789 15.3119 1 10.2199 1C5.12789 1 1 5.12789 1 10.2199C1 15.3119 5.12789 19.4398 10.2199 19.4398C12.1114 19.4398 13.8699 18.8702 15.3332 17.8932L19.4401 22.0001L21.5615 19.8788L17.5265 15.8438Z"
      fill="white"
    />
    <path
      d="M17.5265 15.8438L17.1305 15.5386L16.8622 15.8866L17.1729 16.1974L17.5265 15.8438ZM15.3332 17.8932L15.6867 17.5396L15.3967 17.2495L15.0555 17.4773L15.3332 17.8932ZM19.4401 22.0001L19.0866 22.3536L19.4401 22.7072L19.7937 22.3536L19.4401 22.0001ZM21.5615 19.8788L21.915 20.2323L22.2686 19.8788L21.915 19.5252L21.5615 19.8788ZM18.9398 10.2199C18.9398 12.2227 18.2652 14.0666 17.1305 15.5386L17.9225 16.1491C19.1873 14.5083 19.9398 12.4513 19.9398 10.2199H18.9398ZM10.2199 1.5C15.0358 1.5 18.9398 5.40403 18.9398 10.2199H19.9398C19.9398 4.85175 15.5881 0.5 10.2199 0.5V1.5ZM1.5 10.2199C1.5 5.40403 5.40403 1.5 10.2199 1.5V0.5C4.85175 0.5 0.5 4.85175 0.5 10.2199H1.5ZM10.2199 18.9398C5.40403 18.9398 1.5 15.0358 1.5 10.2199H0.5C0.5 15.5881 4.85175 19.9398 10.2199 19.9398V18.9398ZM15.0555 17.4773C13.6719 18.4012 12.0096 18.9398 10.2199 18.9398V19.9398C12.2133 19.9398 14.0679 19.3392 15.6108 18.309L15.0555 17.4773ZM19.7937 21.6465L15.6867 17.5396L14.9796 18.2467L19.0866 22.3536L19.7937 21.6465ZM21.2079 19.5252L19.0866 21.6465L19.7937 22.3536L21.915 20.2323L21.2079 19.5252ZM17.1729 16.1974L21.2079 20.2323L21.915 19.5252L17.8801 15.4903L17.1729 16.1974Z"
      fill="#232323"
    />
  </Icon>
);

export default SearchIcon;
