import React from 'react';
import styled from 'styled-components';

const MourneLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;

  @media (max-width: 1201px) {
    height: 60px;
  }
`;

const Icon = styled.svg`
  display: block;

  @media (max-width: 600px) {
    width: 260px;
  }

  @media (max-width: 400px) {
    width: 200px;
  }

  @media (max-width: 370px) {
    width: 170px;
  }
`;

const Logo = () => (
  <MourneLogo>
    <Icon
      width="303"
      height="19"
      viewBox="0 0 303 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2 1.7998H15.2V16.4598H13.05V5.0598L8.55 10.7198H8.16L3.6 5.0598V16.4598H1.5V1.7998H3.5L8.37 7.7998L13.2 1.7998Z"
        fill="#323232"
      />
      <path
        d="M34.1099 1.58995C35.1485 1.55744 36.1833 1.73067 37.1547 2.09965C38.126 2.46864 39.0149 3.0261 39.7699 3.73995C40.5014 4.43192 41.0782 5.27077 41.4625 6.20142C41.8469 7.13208 42.03 8.13351 41.9999 9.13995C42.0284 10.1464 41.8395 11.1471 41.446 12.0738C41.0526 13.0005 40.4638 13.8315 39.7199 14.51C38.1618 15.9341 36.1098 16.6946 33.9999 16.6299C32.9845 16.6592 31.9734 16.4859 31.0257 16.1202C30.0779 15.7545 29.2125 15.2037 28.4799 14.5C27.758 13.8165 27.1881 12.9886 26.8073 12.0703C26.4266 11.152 26.2435 10.1637 26.2699 9.16995C26.2392 8.15839 26.4209 7.15163 26.8033 6.21463C27.1857 5.27762 27.7602 4.43119 28.4899 3.72995C29.2372 3.01726 30.1192 2.46088 31.0842 2.09342C32.0492 1.72595 33.0779 1.55478 34.1099 1.58995V1.58995ZM34.1899 3.58995C33.43 3.5617 32.672 3.68608 31.961 3.95574C31.2499 4.2254 30.6001 4.63487 30.0499 5.15995C29.5174 5.67801 29.0985 6.30111 28.8196 6.98971C28.5407 7.6783 28.408 8.41735 28.4299 9.15995C28.4089 9.89501 28.5421 10.6263 28.8212 11.3067C29.1002 11.9871 29.5188 12.6014 30.0499 13.11C31.1594 14.1389 32.6167 14.7107 34.1299 14.7107C35.6431 14.7107 37.1004 14.1389 38.2099 13.11C38.7456 12.5933 39.1679 11.9709 39.4502 11.2823C39.7326 10.5937 39.8687 9.85393 39.8499 9.10995C39.8655 8.37825 39.7276 7.65141 39.4451 6.97625C39.1627 6.30109 38.7419 5.69259 38.2099 5.18995C37.6817 4.66981 37.0548 4.26037 36.3663 3.98562C35.6777 3.71088 34.9412 3.57636 34.1999 3.58995H34.1899Z"
        fill="#323232"
      />
      <path
        d="M63.4101 1.7998H65.5201V10.1198C65.5478 10.9771 65.4638 11.8343 65.2701 12.6698C65.1351 13.1728 64.9298 13.6542 64.6601 14.0998C64.4142 14.4751 64.1143 14.8121 63.7701 15.0998C62.4888 16.141 60.8694 16.6748 59.2201 16.5998C57.556 16.6835 55.9191 16.1533 54.6201 15.1098C54.278 14.8199 53.9784 14.4832 53.7301 14.1098C53.4645 13.6741 53.2624 13.2027 53.1301 12.7098C52.9409 11.8567 52.8602 10.9831 52.8901 10.1098V1.7998H55.0001V10.1198C54.9305 11.1052 55.0986 12.0929 55.4901 12.9998C55.8318 13.5653 56.3327 14.0175 56.9301 14.2998C57.6408 14.6406 58.4221 14.8085 59.2101 14.7898C60.2967 14.8206 61.3593 14.4664 62.2101 13.7898C62.6087 13.4601 62.9153 13.033 63.1001 12.5498C63.3225 11.7532 63.4136 10.9257 63.3701 10.0998L63.4101 1.7998Z"
        fill="#323232"
      />
      <path
        d="M77.08 16.4598V1.79979H80.75C82.0205 1.73082 83.2735 2.12126 84.28 2.89979C84.7138 3.26584 85.0576 3.72682 85.2848 4.24703C85.5119 4.76725 85.6164 5.33275 85.59 5.89979C85.6216 6.67606 85.4158 7.44351 85 8.09979C84.546 8.74532 83.9032 9.23439 83.16 9.49979C83.6749 9.85167 84.1363 10.276 84.53 10.7598C85.2207 11.6436 85.8521 12.5723 86.42 13.5398C86.9333 14.3731 87.3433 14.9998 87.65 15.4198L88.43 16.4198H85.93L85.29 15.4198L85.16 15.2398L84.75 14.6598L84.1 13.5898L83.4 12.4498C83.0427 11.9385 82.6448 11.457 82.21 11.0098C81.9141 10.7078 81.5775 10.4486 81.21 10.2398C80.7446 10.0488 80.2418 9.96672 79.74 9.99979H79.19V16.3798L77.08 16.4598ZM79.81 3.58979H79.18V8.21979H80C80.7404 8.26783 81.4833 8.17624 82.19 7.94979C82.5735 7.77728 82.8947 7.49101 83.11 7.12979C83.3327 6.75135 83.4469 6.31886 83.44 5.87979C83.4433 5.43565 83.3145 5.00056 83.07 4.62979C82.8317 4.26446 82.4805 3.98703 82.07 3.83979C81.3339 3.64131 80.5716 3.55699 79.81 3.58979V3.58979Z"
        fill="#323232"
      />
      <path
        d="M110.25 1.7998H112.25V16.4598H110.45L100.65 5.1698V16.4598H98.65V1.7998H100.35L110.24 13.1898L110.25 1.7998Z"
        fill="#323232"
      />
      <path
        d="M124 1.7998H132.31V3.6698H126.1V8.1398H132.1V9.9998H126.1V14.5498H132.51V16.4198H124V1.7998Z"
        fill="#323232"
      />
      <path
        d="M154 1.78027H166.25V3.65027H161.1V16.4603H159V3.65027H154V1.78027Z"
        fill="#323232"
      />
      <path
        d="M176.79 1.80029H185.1V3.67029H178.89V8.14029H184.89V10.0003H178.89V14.5503H185.3V16.4203H176.79V1.80029Z"
        fill="#323232"
      />
      <path
        d="M206.74 1.80029H209.28L203.42 8.87029L209.69 16.4603H207.16L202.16 10.3803L197.16 16.4603H194.6L200.91 8.87029L195 1.80029H197.56L202.16 7.40029L206.74 1.80029Z"
        fill="#323232"
      />
      <path
        d="M218.7 1.78027H230.95V3.65027H225.84V16.4603H223.73V3.65027H218.73L218.7 1.78027Z"
        fill="#323232"
      />
      <path d="M241.54 1.80029H243.64V16.4603H241.54V1.80029Z" fill="#323232" />
      <path
        d="M255.49 1.80029H257.59V14.5503H264.19V16.4603H255.49V1.80029Z"
        fill="#323232"
      />
      <path
        d="M274.62 1.80029H282.93V3.67029H276.72V8.14029H282.72V10.0003H276.72V14.5503H283.13V16.4203H274.62V1.80029Z"
        fill="#323232"
      />
      <path
        d="M297.6 10.3202L296 9.32018C295.176 8.86495 294.45 8.25355 293.86 7.52018C293.446 6.93592 293.225 6.23637 293.23 5.52018C293.212 4.99255 293.311 4.46747 293.518 3.98196C293.725 3.49645 294.037 3.06232 294.43 2.71018C295.295 1.967 296.411 1.58087 297.55 1.63018C298.749 1.61399 299.924 1.96275 300.92 2.63018V5.00018C300.48 4.53455 299.952 4.16054 299.367 3.89968C298.782 3.63882 298.151 3.49625 297.51 3.48018C296.91 3.45684 296.318 3.62495 295.82 3.96018C295.616 4.09152 295.449 4.2722 295.333 4.48548C295.218 4.69875 295.158 4.9377 295.16 5.18018C295.169 5.63603 295.343 6.07316 295.65 6.41018C296.104 6.89615 296.634 7.30484 297.22 7.62018L298.82 8.57018C299.598 8.93961 300.259 9.51548 300.731 10.235C301.204 10.9544 301.47 11.7899 301.5 12.6502C301.519 13.1835 301.423 13.7146 301.22 14.2079C301.016 14.7012 300.709 15.1452 300.32 15.5102C299.48 16.2638 298.378 16.6587 297.25 16.6102C295.804 16.6253 294.395 16.1533 293.25 15.2702V12.6302C293.69 13.2742 294.277 13.8048 294.962 14.1785C295.647 14.5522 296.41 14.7584 297.19 14.7802C297.785 14.8005 298.368 14.6052 298.83 14.2302C299.038 14.0677 299.205 13.8596 299.32 13.6221C299.434 13.3845 299.492 13.1238 299.49 12.8602C299.517 11.9668 298.887 11.1202 297.6 10.3202Z"
        fill="#323232"
      />
    </Icon>
  </MourneLogo>
);

export default Logo;
