import * as React from 'react';
import { PreviewStoreProvider } from 'gatsby-source-prismic';

// Components
import { StoreProvider } from './src/components/context/store-context';

export const wrapRootElement = ({ element }) => (
  <StoreProvider>
    <PreviewStoreProvider>{element}</PreviewStoreProvider>
  </StoreProvider>
);
