import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const MailchimpFormContainer = styled.div`
  width: 320px;

  & input {
    ::-webkit-input-placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
    ::-moz-placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
    :-ms-input-placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
    :-moz-placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
    ::placeholder {
      font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
        'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
        sans-serif;

      font-size: 14px;
      line-height: 125%;

      color: #323232;
    }
  }

  & .gdpr-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    &.display-on-mobile {
      display: none;
    }

    margin: 0 0 20px 0;

    & p {
      margin: 0;
    }

    & .gdpr-checkbox {
      width: 10px;
      height: 10px;

      position: relative;

      margin: 0 10px 0 0;
    }
    & .gdpr-checkbox label {
      width: 10px;
      height: 10px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #cfcfcf;
    }
    & .gdpr-checkbox label:after {
      content: '';
      width: 6px;
      height: 3px;
      position: absolute;
      top: 2px;
      left: 2px;
      border: 1px solid #323232;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    & .gdpr-checkbox label:hover::after {
      opacity: 1;
    }
    & .gdpr-checkbox input[type='checkbox'] {
      width: 10px;
      height: 10px;

      visibility: hidden;
    }
    & .gdpr-checkbox input[type='checkbox']:checked + label:after {
      opacity: 1;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  box-sizing: content-box;

  & .field {
    border: 1px solid #cfcfcf;
    background-color: #fff;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    width: 100%;
    margin: 0;
  }

  & input {
    width: calc(100% - 36px);

    font-size: 14px;
    line-height: 125%;

    padding: 3px 4px 2px 4px;
    margin: 0;
    background-color: #fff;
    -webkit-appearance: none;

    border: 0;

    color: #323232;
    position: relative;

    &:focus {
      outline: none;
    }
  }
`;

const ThankYouMessage = styled.div`
  margin: 20px 0 0 0;

  & p {
    margin: 0;
    line-height: 24px;
  }
`;

const SignUpButton = styled.button`
  width: 28px;
  height: 28px;

  margin: 0;
  background-color: #934e2a;

  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.9px;
  text-transform: uppercase;

  color: #fff;

  transition: 150ms all ease;
  will-change: all;

  &:hover {
    background-color: rgba(147, 78, 42, 0.8);
  }
`;

const Heading = styled.div`
  margin: 0 0 15px 0;

  & h2 {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    font-size: 14px;
    line-height: 1;
  }
`;

export const FooterMailchimpSignUpForm = ({ onClick, opacity }) => {
  const [formMessage, setFormMessage] = useState('');

  const [email, setEmail] = useState('');
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');

  const data = useStaticQuery(graphql`
    {
      prismicMailchimp {
        data {
          title {
            html
          }
          text {
            html
          }
          call_to_action_text
          privacy_policy {
            html
          }
          thank_you_message {
            html
          }
        }
      }
    }
  `);

  // 1. via `.then`
  const handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(email, { FNAME: fname, LNAME: lname }) // listFields are optional if you are only capturing the email address.
      .then(mailchimpData => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (mailchimpData.result === `error`) {
          if (mailchimpData.msg === 'The email you entered is not valid.') {
            setFormMessage(`Invalid Email`);
          }

          if (mailchimpData.msg.includes('already subscribed')) {
            setFormMessage(`Email is already subscribed`);
          }
        }

        if (mailchimpData.result === `success`) {
          setFormMessage(data.prismicMailchimp.data.thank_you_message.html);
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  return (
    <MailchimpFormContainer opacity={opacity}>
      <Heading>
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: data.prismicMailchimp.data.title.html,
          }}
        />
      </Heading>

      <>
        <form onSubmit={e => handleSubmit(e)} className="mailchimp-signup-form">
          <InputContainer>
            <div className="field">
              <input
                type="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                placeholder={`Email`}
                name="email"
                required
                autoComplete="email"
              />
              <SignUpButton type="submit">→</SignUpButton>
            </div>

            {/* <div className="gdpr-container">
              <div className="gdpr-checkbox">
                <input
                  type="checkbox"
                  value="None"
                  id="gdpr-checkbox"
                  name="gdprcheck"
                  required
                />
                <label htmlFor="gdpr-checkbox"></label>
              </div>

              <PrivacyMessage
                className="small-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicMailchimp.data.privacy_policy.html,
                }}
              />
            </div> */}
          </InputContainer>
        </form>

        <ThankYouMessage
          dangerouslySetInnerHTML={{
            __html: formMessage,
          }}
        />
      </>
    </MailchimpFormContainer>
  );
};
