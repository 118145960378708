import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const News = styled.div`
  position: relative;
  width: 100%;

  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  padding: 20px;

  & p {
    margin: 0;

    font-size: 12px;
    line-height: 12px;
    color: #fff;
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
  }
`;

export const NewsBar = () => {
  const data = useStaticQuery(graphql`
    {
      prismicNewsBar {
        data {
          display_news_bar
          text {
            html
          }
          background_color
          text_color
        }
      }
    }
  `);
  return (
    <News
      backgroundColor={data.prismicNewsBar.data.background_color}
      textColor={data.prismicNewsBar.data.text_color}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicNewsBar.data.text.html,
        }}
      />
    </News>
  );
};
