import React, { Component } from 'react';
import { Index } from 'elasticlunr';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Components
import SearchIcon from '../icons/search';

const SearchContainer = styled.div`
  margin: 0 0 0 20px;
  width: ${props => (props.isSearchOpen ? '100%' : 'auto')};
`;

const SearchButton = styled.button`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin: -2px 0 0 0 !important;
`;

const GoButton = styled.button`
  padding: 2px 5px;
  line-height: 20px;

  background-color: #323232;

  transition: 150ms all ease;
  will-change: all;

  & > a {
    margin: 0 !important;

    font-size: 10px !important;
    text-transform: uppercase !important;
    color: #fff !important;
  }

  & .disabled-link {
    pointer-events: none;
  }
`;

const SearchInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: calc(100% - 23px);
  }

  & input {
    height: 25px;
    width: calc(100% - 55px - 20px);

    margin: 0;

    border: 1px solid #e8e8e8;
    border-right: 1px solid #323232;
    border-radius: 0;

    transition: 250ms border-color ease;

    padding: 2px 5px;

    font-size: 12px;
    color: #323232;

    &:focus {
      border: 1px solid #323232;
      outline: none;
    }
  }
`;

const CloseIcon = styled.span`
  width: 23px;
  font-size: 20px;
`;

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ``,
      results: [],
    };
  }

  render() {
    const searchResults = this.state.results;
    const searchQuery = this.state.query;

    // https://dev.to/steelvoltage/tip-disabling-buttons-as-links-in-gatsby-3o5n
    const disableLink = searchQuery ? '' : 'disabled-link';

    return (
      <SearchContainer isSearchOpen={this.props.isSearchOpen}>
        <SearchInner>
          {this.props.isSearchOpen && (
            <div>
              <input
                type="text"
                value={this.state.query}
                onChange={this.search}
                id="search-input"
              />

              <GoButton>
                <Link
                  disabled={this.state.query === ''}
                  className={`button ${disableLink}`}
                  to={`/search`}
                  state={{
                    searchResults,
                    searchQuery,
                  }}
                  onClick={() => this.props.onClick()}
                >
                  Search
                </Link>
              </GoButton>
            </div>
          )}

          <SearchButton onClick={() => this.props.onClick()}>
            {!this.props.isSearchOpen && <SearchIcon />}
            {this.props.isSearchOpen && <CloseIcon>✕</CloseIcon>}
          </SearchButton>
        </SearchInner>
      </SearchContainer>
    );
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex);

  search = evt => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    });
  };
}
